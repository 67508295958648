<template>
    <div>
        <el-tabs v-model="activeNameForExcelImport">
            <el-tab-pane label="Success" name="success">
                <span slot="label">
                    Success
                    <span style="color: #071aed">
                        {{ this.successedExcelImportData.length }}
                    </span>
                </span>
                <div class="table-container" style="width: 100%;overflow-x: auto;">
                    <el-table :data="successedExcelImportDataPagination" border @cell-mouse-enter="editSuccessedExcelImportData"
                        @cell-mouse-leave="resetSuccessedExcelImportData" style="width:100%; overflow-x: auto"
                        v-loading="isTableDataLoading" element-loading-text="Loading..."
                        >
                        <el-table-column v-for="(row, index) in foundArray" :key="index" :label="row.label" :width="220">
                            <template slot-scope="scope">
                                <div v-if="row && row.input_type == 'SIGNATURE'">
                                    <img v-if="scope.row[row.template_id][row.key]" :src="scope.row[row.template_id][row.key]"/>
                                </div>
                                <div v-else-if="row && row.input_type == 'DATE'">
                                    <el-date-picker style="width: 200px" v-model="scope.row[row.template_id][row.key]">
                                    </el-date-picker>
                                </div>
                                <div v-else-if="row && row.input_type == 'TIME'">
                                    <el-time-picker  style="width: 200px"
                                        v-model="scope.row[row.template_id][row.key]"
                                        placeholder="Select time">
                                    </el-time-picker>
                                </div>
                                <div v-else-if="row && row.input_type == 'SELECT'">
                                    <el-select clearable v-model="scope.row[row.template_id][row.key]" placeholder="Select" v-if="!row.is_global_variable">
                                        <el-option v-for="item in row.options" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                    <el-select clearable v-model="scope.row[row.template_id][row.key]" placeholder="Select" v-else>
                                        <el-option v-for="item in globalVariables[`${row.template_id}#${row.key}`]" :key="item" :value="item" :label="item">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div v-else-if="row && row.input_type == 'MULTI_SELECT'">
                                    <el-select multiple collapse-tags v-model="scope.row[row.template_id][row.key]"
                                        placeholder="Select" v-if="!row.is_global_variable">
                                        <el-option v-for="item in row.options" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                    <el-select multiple collapse-tags v-model="scope.row[row.template_id][row.key]"
                                        placeholder="Select" v-else>
                                        <el-option v-for="item in globalVariables[`${row.template_id}#${row.key}`]" :key="item" :value="item" :label="item">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div v-else-if="row && row.input_type == 'FORMULA'">
                                    <el-input v-model="row.formula" :disabled="true">
                                    </el-input>
                                </div>
                                <div v-else-if="row && row.input_type == 'NUMBER'">
                                    <el-input v-model="scope.row[row.template_id][row.key]" type="number">
                                    </el-input>
                                </div>
                                <div v-else>
                                    <el-input v-model="scope.row[row.template_id][row.key]">
                                    </el-input>
                                </div>
                                <span style="font-size: 10px; color: red; font-weight: 700" v-if="checkFieldData(
                                        scope.row[row.template_id][row.key],
                                        row
                                    ) &&
                                    !checkFieldData(
                                        scope.row[row.template_id][row.key],
                                        row
                                    ).success
                                    ">
                                    {{
                                        checkFieldData(
                                            scope.row[row.template_id][row.key],
                                            row
                                        ).message
                                    }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-pagination
                  style="text-align: center"
                  @size-change="handleSizeChangeForIEDS"
                  @current-change="handleCurrentChangeForIEDS"
                  :current-page.sync="currentPageForIEDS"
                  :page-sizes="[5, 10, 20, 50]"
                  :page-size="pageSizeForIEDS"
                  layout="prev, pager, next, jumper, total"
                  :total="successedExcelImportData.length"
                ></el-pagination>
            </el-tab-pane>
            <el-tab-pane label="Errors" name="errors">
                <span slot="label">
                    Errors
                    <span style="color: #f21307">
                        {{ this.errorExcelImportData.length }}
                    </span>
                </span>
                <div class="table-container" style="width: 100%;overflow-x: auto;">
                    <el-table :data="errorExcelImportDataPagination" border @cell-mouse-enter="editErrorExcelImportData"
                        @cell-mouse-leave="resetErrorExcelImportData" style="width:100%; overflow-x: auto">
                        <el-table-column v-for="(row, index) in foundArray" :key="index" :label="row.label" :width="220">
                            <template slot-scope="scope">
                                <div v-if="row && row.inputType == 'DATE'">
                                    <el-date-picker style="width: 200px" v-model="scope.row[row.template_id][row.key]">
                                    </el-date-picker>
                                </div>
                                <div v-else-if="row && row.inputType == 'SELECT'">
                                    <el-select clearable v-model="scope.row[row.template_id][row.key]" placeholder="Select">
                                        <el-option v-for="item in row.options" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div v-else-if="row && row.inputType == 'MULTI_SELECT'">
                                    <el-select multiple collapse-tags v-model="scope.row[row.template_id][row.key]"
                                        placeholder="Select">
                                        <el-option v-for="item in row.options" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div v-else-if="row && row.inputType == 'FORMULA'">
                                    <el-input v-model="row.formula" :disabled="true">
                                    </el-input>
                                </div>
                                <div v-else-if="row && row.inputType == 'NUMBER'">
                                    <el-input v-model="scope.row[row.template_id][row.key]" type="number">
                                    </el-input>
                                </div>
                                <div v-else>
                                    <el-input v-model="scope.row[row.template_id][row.key]">
                                    </el-input>
                                </div>
                                <span style="font-size: 10px; color: red; font-weight: 700" v-if="checkFieldData(
                                        scope.row[row.template_id][row.key],
                                        row
                                    ) &&
                                    !checkFieldData(
                                        scope.row[row.template_id][row.key],
                                        row
                                    ).success
                                    ">
                                    {{
                                        checkFieldData(
                                            scope.row[row.template_id][row.key],
                                            row
                                        ).message
                                    }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-pagination
                  style="text-align: center"
                  @size-change="handleSizeChangeForIE"
                  @current-change="handleCurrentChangeForIE"
                  :current-page.sync="currentPageForIE"
                  :page-sizes="[5, 10, 20, 50]"
                  :page-size="pageSizeForIE"
                  layout="prev, pager, next, jumper, total"
                  :total="errorExcelImportData.length"
                ></el-pagination>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>
<script>
import { mapGetters} from "vuex";
export default {
    name: 'entities-SuccessAndErrorsTable',
    components: {
},
    props: [
        'templatesData',
        'currentEntity',
        'foundArray',
        'successedExcelImportData'
    ],
    mounted() {
        this.loading = true
        // this.makeSuccessAndErrorData()
        this.getGlobalVariablesData()
        this.loading = false

    },
    data() {
        return {
            isTableDataLoading:false,
            activeNameForExcelImport: "success",
            errorExcelImportData: [],
            pageSizeForIEDS: 5,
            currentPageForIEDS: 1,
            pageSizeForIE: 5,
            currentPageForIE: 1,
            standardTemp : {},
            loading : false,
            globalVariables : {}
        }
    },
    methods: {
        makeSuccessAndErrorData() {
            if (this.templatesData.length) {
                let standardTemp
                let standardTempId
                if (this.currentEntity.entity_type === "INDIVIDUAL") {
                    standardTemp = this.currentEntity.templates.find((temp) => {
                        if (
                            (temp &&
                                temp.template_id &&
                                temp.template_id.type === "STANDARD") ||
                            (temp && temp.templateInfo && temp.templateInfo.type === 'STANDARD')
                        ) {
                            return true;
                        }
                    });
                    this.standardTemp = standardTemp;
                    if (this.templatesData[0][standardTemp._id]) {
                        standardTempId = standardTemp._id
                    }
                    else {
                        standardTempId = standardTemp.template_id
                    }
                }
                this.templatesData.forEach((rowData) => {
                    if (this.currentEntity.entity_type === "INDIVIDUAL") {
                        if (rowData[standardTemp._id] || rowData[standardTemp.template_id]) {
                            if (
                                ((Object.keys(rowData[standardTempId]).indexOf(
                                    "first_name"
                                ) !== -1 &&
                                    Object.keys(rowData[standardTempId]).indexOf(
                                        "last_name"
                                    ) !== -1) ||
                                    Object.keys(rowData[standardTempId]).indexOf(
                                        "name"
                                    ) !== -1) &&
                                Object.keys(rowData[standardTempId]).indexOf(
                                    "email"
                                ) !== -1
                            ) {
                                if (!rowData[standardTempId]["first_name"]) {
                                    if (!rowData[standardTempId]["name"]) {
                                        this.errorExcelImportData.push(rowData);
                                        return;
                                    }
                                }
                                if (!rowData[standardTempId]["last_name"]) {
                                    if (!rowData[standardTempId]["name"]) {
                                        this.errorExcelImportData.push(rowData);
                                        return;
                                    }
                                }
                                // if (Object.keys(rowData[standardTempId]).indexOf('phone_number') !== -1) {
                                //     if (rowData[standardTempId]['phone_number'] !== '') {
                                //         const phoneValidation = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
                                //         if (!phoneValidation.test(rowData[standardTempId]['phone_number'])) {
                                //             this.errorExcelImportData.push(rowData);
                                //             return
                                //         }
                                //     }
                                // }
                                if (rowData[standardTempId]["email"] !== "" && rowData[standardTempId]['email'] !== undefined && rowData[standardTempId]['email'] !== null) {
                                    if (rowData[standardTempId]['email'].toString() === this.getAuthenticatedUser.email.toString()) {
                                        this.errorExcelImportData.push(rowData)
                                        return
                                    }
                                    var mailformat =
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    if (
                                        // rowData[standardTempId]["email"].match(
                                        //     mailformat
                                        // ) === null
                                        !mailformat.test(rowData[standardTempId]['email'])
                                    ) {
                                        this.errorExcelImportData.push(rowData);
                                        return;
                                    }
                                } else {
                                    this.errorExcelImportData.push(rowData);
                                    return;
                                }
                            } else {
                                console.log("everything")
                                this.errorExcelImportData.push(rowData);
                                return;
                            }
                        } else {
                            //if standrd temp not found
                            console.log("no standardTemp")
                            this.errorExcelImportData.push(rowData);
                            return;
                        }
                        this.successedExcelImportData.push(rowData);
                    } else if (
                        this.currentEntity.entity_type === "GENERAL" ||
                        this.currentEntity.entity_type === "BUSINESS"
                    ) {
                        //need to write primary fields conditions
                        let result = this.currentEntity.primaryFields.every((pr) => {
                            if (
                                pr.template_id &&
                                rowData[pr.template_id] &&
                                rowData[pr.template_id][pr.key]
                            ) {
                                return true;
                            }
                        });
                        if (result) {
                            this.successedExcelImportData.push(rowData);
                        } else {
                            this.errorExcelImportData.push(rowData);
                        }
                    }
                });
            }
        },
        validateSelectedRow(rowData) {
            //1st part
            let entity = this.currentEntity;
            let standardTemp;
            let standardTempId
            if (entity.entity_type === "INDIVIDUAL") {
                standardTemp = entity.templates.find((temp) => {
                    if (
                        (temp &&
                        temp.template_id &&
                        temp.template_id.type === "STANDARD") ||
                        (temp && temp.templateInfo && temp.templateInfo.type === 'STANDARD')
                    ) {
                        return true;
                    }
                });
                if (rowData[standardTemp._id]) {
                    standardTempId = standardTemp._id
                }
                else {
                    standardTempId = standardTemp.template_id
                }
            }
            
            //second part
            if (rowData) {
                if (entity.entity_type === "INDIVIDUAL") {
                    if (rowData[standardTemp._id] || rowData[standardTemp.template_id]) {
                        // if (Object.keys(rowData[standardTempId]).indexOf('phone_number') !== -1) {
                        //     if (rowData[standardTempId]['phone_number'] !== '') {
                        //         const phoneValidation = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
                        //         if (!phoneValidation.test(rowData[standardTempId]['phone_number'])) {
                        //             return {
                        //                 success: false
                        //             }
                        //         }
                        //     }
                        // }
                        if (
                            ((Object.keys(rowData[standardTempId]).indexOf(
                                "first_name"
                            ) !== -1 &&
                                Object.keys(rowData[standardTempId]).indexOf(
                                    "last_name"
                                ) !== -1) ||
                                Object.keys(rowData[standardTempId]).indexOf(
                                    "name"
                                ) !== -1) &&
                            Object.keys(rowData[standardTempId]).indexOf(
                                "email"
                            ) !== -1
                        ) {
                            if (!rowData[standardTempId]["first_name"]) {
                                if (!rowData[standardTempId]["name"]) {
                                    return {
                                        success: false,
                                    };
                                }
                            }
                            if (!rowData[standardTempId]["last_name"]) {
                                if (!rowData[standardTempId]["name"]) {
                                    return {
                                        success: false,
                                    };
                                }
                            }
                            if (rowData[standardTempId]["email"] !== "" && rowData[standardTempId]['email'] !== undefined && rowData[standardTempId]['email'] !== null) {
                                if(rowData[standardTempId]['email'].toString() === this.getAuthenticatedUser.email.toString()){
                                    return {
                                        success : false,
                                    }
                                }
                                var mailformat =
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                if (
                                    // rowData[standardTempId]["email"].match(
                                    //     mailformat
                                    // ) === null
                                    !mailformat.test(rowData[standardTempId]['email'])
                                ) {
                                    return {
                                        success: false,
                                    };
                                }
                            } else {
                                return {
                                    success: false,
                                };
                            }
                        } else {
                            return {
                                success: false,
                            };
                        }
                    } else {
                        //No standard Template found
                        return {
                            success: false,
                        };
                    }
                    return {
                        success: true,
                    };
                } else if (
                    entity.entity_type === "GENERAL" ||
                    entity.entity_type === "BUSINESS"
                ) {
                    //need to write primary fields conditions
                    let result = entity.primaryFields.every((pr) => {
                        if (
                            pr.template_id &&
                            rowData[pr.template_id] &&
                            rowData[pr.template_id][pr.key]
                        ) {
                            return true;
                        }
                    });
                    if (result) {
                        return {
                            success: true,
                        };
                    } else {
                        return {
                            success: false,
                        };
                    }
                }
            }
            //validate selected row
        },
        editSuccessedExcelImportData(row) {
            this.currentActiveSuccessRowIndex =
                this.successedExcelImportData.indexOf(row);
        },
        resetSuccessedExcelImportData() {
            let selectedRow =
                this.successedExcelImportData[this.currentActiveSuccessRowIndex];
            let isVaild = this.validateSelectedRow(selectedRow);
            if (isVaild && isVaild.success === false) {
                this.errorExcelImportData.push(selectedRow);
                this.successedExcelImportData.splice(
                    this.currentActiveSuccessRowIndex,
                    1
                );
            }
            this.pageSizeForIEDS = 0
            this.pageSizeForIEDS = 5
        },
        editErrorExcelImportData(row) {
            this.currentActiveErrorRowIndex = this.errorExcelImportData.indexOf(row);
        },
        resetErrorExcelImportData() {
            let selectedRow =
                this.errorExcelImportData[this.currentActiveErrorRowIndex];
            let isValid = this.validateSelectedRow(selectedRow);
            if (isValid && isValid.success === true) {
                this.successedExcelImportData.push(selectedRow);
                this.errorExcelImportData.splice(this.currentActiveErrorRowIndex, 1);
            }
        },
        checkFieldData(individualFieldValue, foundedField) {
            if (foundedField && individualFieldValue) {
                if (this.currentEntity.entity_type === 'INDIVIDUAL' &&(foundedField && (foundedField.template_id.toString() === this.standardTemp._id.toString() || foundedField.template_id.toString() === this.standardTemp.template_id.toString()))) {
                    // if (foundedField.key === 'phone_number' && individualFieldValue.toString() !== '') {
                    //     const phoneNumberFormat = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/
                    //     if (!phoneNumberFormat.test(individualFieldValue)) {
                    //         return {
                    //             success: false,
                    //             data: individualFieldValue,
                    //             message: 'Invalid Phone Number'
                    //         }
                    //     }
                    // }
                    if (foundedField.key === 'email') {
                        if(individualFieldValue.toString() === this.getAuthenticatedUser.email.toString()){
                            return{
                                success : false,
                                data: individualFieldValue,
                                message : 'This is sender email'
                            }
                        }
                        const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if (!emailFormat.test(individualFieldValue)) {
                            return {
                                success: false,
                                data: individualFieldValue,
                                message: 'Invalid Email'
                            }
                        }
                    }
                }
                else if (foundedField.input_type === "SELECT") {
                    individualFieldValue=individualFieldValue.toString();
                    let individualFieldValueLower = individualFieldValue.toLowerCase();
                    let selectArr = [];
                    let options;
                    if(!foundedField.is_global_variable){
                        options = foundedField.options
                    }
                    else{
                        // let key = foundedField.template_id + '#' + foundedField.key
                        // console.log("key",key)
                        // console.log("gllllllllllllllll",Promise.all(this.globalVariables))
                        // console.log("options",this.globalVariables[key])
                        // options = this.globalVariables[key]
                        options = foundedField.options
                    }
                    options.forEach((e) => {
                        e = e.trim();
                        selectArr.push(e.toLowerCase());
                    });
                    let isExisted = selectArr.includes(individualFieldValueLower.trim());
                    if (isExisted) {
                        return {
                            success: true,
                            data: individualFieldValue,
                        };
                    } else {
                        return {
                            success: false,
                            data: "",
                            message: "Entered data not matched",
                        };
                    }
                } else if (foundedField && foundedField.input_type === "MULTI_SELECT") {
                    let resultArr = [];
                    let options;
                    if(!foundedField.is_global_variable){
                        options = foundedField.options
                    }
                    else{
                        options = this.globalVariables[`${foundedField.template_id}#${foundedField.key}`]
                    }
                    if (typeof individualFieldValue == "object") {
                        if (individualFieldValue.length > -1) {
                            resultArr = options.filter((e) => {
                                return (
                                    individualFieldValue
                                        .map((el) => el.toLowerCase())
                                        .indexOf(e) != -1
                                );
                            });
                        }
                    } else {
                        if (individualFieldValue.includes(",")) {
                            let multiArr = individualFieldValue
                                .split(",")
                                .map((e) => e.toLowerCase());
                            // let smallMultiArr = [];
                            resultArr = options.filter((op) => {
                                if (op && multiArr.indexOf(op.toLowerCase()) != -1) {
                                    return op;
                                }
                            });
                        } else {
                            let found = options.find((e) => {
                                if (
                                    e &&
                                    e.toLowerCase() == individualFieldValue.toLowerCase()
                                ) {
                                    return e;
                                }
                            });
                            resultArr = found ? [found] : [];
                        }
                    }
                    return {
                        success: true,
                        data: resultArr,
                    };
                } else if (foundedField && foundedField.inputType === "CHECKBOX") {
                    if (typeof individualFieldValue === "boolean") {
                        return {
                            success: true,
                            data: individualFieldValue,
                        };
                    } else {
                        return {
                            success: false,
                            data: false,
                            message: "Entered data not matched",
                        };
                    }
                } else if (
                    foundedField &&
                    foundedField.inputType === "CHECKBOX_GROUP"
                ) {
                    // let options = [];
                    // foundedField.options.forEach((e) => {
                    //   options.push(e.toLowerCase());
                    // });
                    let resultArr = [];
                    if (typeof individualFieldValue == "object") {
                        if (individualFieldValue.length > -1) {
                            resultArr = foundedField.options.filter((e) => {
                                return (
                                    individualFieldValue
                                        .map((el) => el.toLowerCase())
                                        .indexOf(e) != -1
                                );
                            });
                        }
                    } else {
                        if (individualFieldValue.includes(",")) {
                            let inputArr = individualFieldValue
                                .split(",")
                                .map((e) => e.toLowerCase());
                            resultArr = foundedField.options.filter((op) => {
                                if (op && inputArr.indexOf(op.toLowerCase()) != -1) {
                                    return op;
                                }
                            });
                        } else {
                            let found = foundedField.options.find((e) => {
                                if (
                                    e &&
                                    e.toLowerCase() == individualFieldValue.toLowerCase()
                                ) {
                                    return e;
                                }
                            });
                            resultArr = found ? [found] : [];
                        }
                    }
                    // else {
                    //   let isExisted = options.includes(
                    //     individualFieldValue.toLowerCase()
                    //   );
                    //   if (isExisted) {
                    //     resultantArr.push(individualFieldValue.toLowerCase());
                    //   }
                    // }
                    if (
                        resultArr.length >= foundedField.min_selection &&
                        resultArr.length <= foundedField.max_selection
                    ) {
                        return {
                            success: true,
                            data: resultArr,
                        };
                    } else {
                        resultArr = [];
                        return {
                            success: false,
                            data: resultArr,
                        };
                    }
                } else if (foundedField && foundedField.inputType === "DATE") {
                    var parsedDate = Date.parse(individualFieldValue);
                    if (isNaN(parsedDate)) {
                        return {
                            success: false,
                            data: "",
                            message: "Entered data not matched",
                        };
                    } else {
                        if (typeof individualFieldValue === "number") {
                            const d = new Date(
                                Math.round((individualFieldValue - 25569) * 86400 * 1000)
                            );
                            individualFieldValue = d;
                        }
                        return {
                            success: true,
                            data: individualFieldValue,
                        };
                    }
                }
                return {
                    success: true,
                    data: individualFieldValue,
                };
            }
            else if(foundedField && foundedField.inputType === 'NUMBER'){
                if (typeof individualFieldValue !== 'number') {
                    return {
                        success: false,
                        message: "Field is not a number",
                        data: individualFieldValue
                    }
                }
                else {
                    return {
                        success: true,
                        data: individualFieldValue
                    }
                }
            }
            else if(foundedField && foundedField.inputType == 'FORMULA'){
                return{
                    success : true,
                    data : individualFieldValue 
                }
            }
            let primaryFieldsIds = this.currentEntity.primaryFields.map(e=>{
                return e.key
            })
            return {
                success: false,
                data: "",   
                message:  primaryFieldsIds && primaryFieldsIds.length && primaryFieldsIds.includes(foundedField.key) ? "Field is empty (PRIMARY FIELD)" : "Field is empty"
            };
        },
        handleSizeChangeForIE(val) {
            this.pageSizeForIE = val;
        },
        handleCurrentChangeForIE(val) {
            this.isTableDataLoading = true;
            this.currentPageForIE = val;
            setTimeout(() => {
                this.isTableDataLoading = false;
                }, 1000);
        },
        handleSizeChangeForIEDS(val) {
            this.pageSizeForIEDS = val;
        },
        handleCurrentChangeForIEDS(val) {
            this.isTableDataLoading = true;
            this.currentPageForIEDS = val;
                            setTimeout(() => {
                this.isTableDataLoading = false;
                }, 1000);
        },
        async getGlobalVariablesData() {
            await Promise.all(this.foundArray.map(async (e) => {
                if (e.is_global_variable == true && (e.input_type == 'SELECT' || e.input_type == 'MULTI_SELECT')) {
                    let params = {
                        id: e.global_variable_id
                    };
                    await this.$store.dispatch(
                        "globalVariables/fetchGlobalVariableById",
                        params
                    );
                    if (this.getGlobalVariableById) {
                        this.globalVariables[`${e.template_id}#${e.key}`] = this.getGlobalVariableById.options
                    }
                }
            }))
        }
    },
    computed : {
        ...mapGetters("auth", ["getAuthenticatedUser"]),
        ...mapGetters("globalVariables", ["getGlobalVariableById"]),
        successedExcelImportDataPagination() {
            const from = (this.currentPageForIEDS - 1) * this.pageSizeForIEDS;
            let to = from + this.pageSizeForIEDS;
            return this.successedExcelImportData.slice(from ,to)
            
        },
        errorExcelImportDataPagination() {
            const from = (this.currentPageForIE - 1) * this.pageSizeForIE;
            let to = from + this.pageSizeForIE;
            return this.errorExcelImportData.slice(from, to);
        },
    },
    watch: {
        'currentEntity': {
            handler(){
                this.makeSuccessAndErrorData()
            },
            deep : true,
            immediate : true
        }
    }
}
</script>